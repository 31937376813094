$primaryColor: #313D85;
$dangerColor: #FF0000;
$dangerColorNew: #DC492B;

.bg-primary-pg {
  background-color: $primaryColor;
}

.text-primary-pg {
  color: $primaryColor;
}

.border-primary-pg {
  border-color: $primaryColor !important;
}

.bg-danger-pg {
  background-color: $dangerColor !important;
}

.text-danger-pg {
  color: $dangerColor;
}

.border-danger-pg {
  border-color: $dangerColor !important;
}

.bg-danger-new-pg {
  background-color: $dangerColorNew !important;
}

.border-danger-new-pg {
  border-color: $dangerColorNew !important;
}

.text-danger-new-pg {
  color: $dangerColorNew;
}