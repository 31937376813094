// 
// ecommerce.scss
//

// product

.search-box {
    .form-control {
        // border-radius: 30px;
        border-radius: 0px;
        padding-left: 40px;
    }

    .search-icon {
        font-size: 16px;
        position: absolute;
        left: 13px;
        top: 0;
        line-height: 38px;
    }
}

.product-list {
    li {
        a {
            display: flex;
            padding: 4px 0px;
            justify-content: space-between;
            color: var(--#{$prefix}body-color);

            i {
                &::before {
                    font-weight: bold;
                }
            }
        }
    }
}

.product-view-nav {
    &.nav-pills {
        .nav-item {
            margin-left: 4px;
        }

        .nav-link {
            width: 36px;
            height: 36px;
            font-size: 16px;
            padding: 0;
            line-height: 36px;
            text-align: center;
            border-radius: 50%;
        }
    }
}

.product-ribbon {
    position: absolute;
    right: 0px;
    top: 0px;
}

// Product Details

.product-detai-imgs {
    .nav {
        .nav-link {
            margin: 7px 0px;

            &.active {
                background-color: var(--#{$prefix}tertiary-bg);
            }
        }
    }
}

.product-color {
    a {
        display: inline-block;
        text-align: center;
        color: var(--#{$prefix}body-color);

        .product-color-item {
            margin: 7px;
        }

        &.active,
        &:hover {
            color: $primary;

            .product-color-item {
                border-color: $primary !important;
            }
        }
    }
}

// ecommerce cart

.visa-card {
    .visa-logo {
        line-height: 0.5;
    }

    .visa-pattern {
        position: absolute;
        font-size: 385px;
        color: rgba($white, 0.05);
        line-height: 0.4;
        right: 0px;
        bottom: 0px;
    }
}


// checkout

.checkout-tabs {
    .nav-pills {
        .nav-link {
            margin-bottom: 24px;
            text-align: center;
            background-color: var(--#{$prefix}secondary-bg);
            box-shadow: $box-shadow;

            &.active {
                background-color: $primary;
            }

            .check-nav-icon {
                font-size: 36px;
            }
        }
    }
}